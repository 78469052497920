






























import { Vue, Component, Prop } from 'vue-property-decorator';

import Header from './components/Header/Header.vue';
import Observation from './components/Observation/Observation.vue';
import Redaction from './components/Redaction/Redaction.vue';
import Performance from './components/Performance/Performance.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { Redaction as IRedaction } from './interface';

import { STATUS } from '@/constant/Redaction';

@Component({
  components: {
    Header,
    Observation,
    Redaction,
    Performance,
    FeedbackUser
  }
})
export default class RedactionCorrection extends Vue {
  @Prop({ required: true }) redaction!: IRedaction;

  private isActiveCorrection = true;
  private STATUS = STATUS;

  setActiveCorrection(value: boolean) {
    this.isActiveCorrection = value;
  }
}
