






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Box extends Vue {
  @Prop({}) background!: string;
}
