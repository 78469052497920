






import { Vue, Component, Prop } from 'vue-property-decorator';

import { STATUS_CLASS, STATUS_FEEDBACK } from '../../constants';
import { STATUS } from '@/constant/Redaction';

@Component({})
export default class Status extends Vue {
  @Prop({ required: true }) status!: string;

  get statusClass() {
    const status = {
      class: '',
      text: ''
    };

    switch (this.status) {
      case STATUS.PENDENT:
        status.class = STATUS_CLASS.PENDENT;
        status.text = STATUS_FEEDBACK.PENDENT;
        break;

      case STATUS.REJECTED:
        status.class = STATUS_CLASS.REJECTED;
        status.text = STATUS_FEEDBACK.REJECTED;
        break;

      default:
        status.class = STATUS_CLASS.PENDENT;
        status.text = STATUS_FEEDBACK.PENDENT;
        break;
    }

    return status;
  }
}
