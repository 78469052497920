



































import { Vue, Component, Prop } from 'vue-property-decorator';

import Note from './components/Note/Note.vue';
import Total from './components/Total/Total.vue';
import Average from './components/Average/Average.vue';

import { CONST_RESULT, SUBJECT, TOTAL_SUBJECT } from './constants';

import { TRI } from '@/globalInterfaces/List';

@Component({
  components: {
    Note,
    Total,
    Average
  }
})
export default class NotesTRI extends Vue {
  @Prop({ required: true }) tri!: TRI;

  private dataResult = CONST_RESULT;

  get scores() {
    const result = this.dataResult.scores.map((score) => score.group.map((group, index: number) => {
      if (index === 0) {
        return {
          ...group,
          value: this.tri[SUBJECT[group.id]]?.points || 0
        };
      }

      if (index === 1) {
        return {
          ...group,
          value: this.tri[SUBJECT[group.id]]?.hists || 0
        };
      }

      return {
        ...group,
        value:
            this.tri[SUBJECT[group.id]]?.totalQuestions - this.tri[SUBJECT[group.id]]?.hists || 0
      };
    }));

    return result;
  }

  get average() {
    const total = Object.keys(this.tri).reduce(
      (acc, subject) => acc + (this.tri[subject]?.points || 0),
      0
    );

    const average = total / TOTAL_SUBJECT || 0;

    return average.toFixed(2);
  }
}
