


















































import { Vue, Component, Prop } from 'vue-property-decorator';

import Status from '../Status/Status.vue';

import { Redaction } from '../../interface';

import { STATUS } from '@/constant/Redaction';

@Component({
  components: {
    Status
  }
})
export default class Header extends Vue {
  @Prop({ required: true }) isActiveCorrection!: boolean;
  @Prop({ required: true }) redaction!: Redaction;

  private STATUS = STATUS;

  get isShowStatusComponent() {
    return (
      this.redaction.status === this.STATUS.PENDENT
      || this.redaction.status === this.STATUS.REJECTED
    );
  }

  setActiveCorrection(value: boolean) {
    this.$emit('onMenu', value);
  }
}
