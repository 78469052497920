
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Note extends Vue {
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() value!: number;
}
