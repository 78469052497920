export const CONST_RESULT = {
  scores: [
    {
      group: [
        {
          id: 'scoreLanguage',
          title: 'Linguagens e Códigos (LC)',
          subtitle: 'Nota com base no TRI',
          value: 0
        },
        {
          id: 'lc_lt',
          title: 'Total de acertos',
          subtitle: '',
          value: 0
        },
        {
          id: 'lc_lt',
          title: 'Total de erros',
          subtitle: '',
          value: 0
        }
      ]
    },
    {
      group: [
        {
          id: 'scoreHuman',
          title: 'Ciências Humanas (CH)',
          subtitle: 'Nota com base no TRI',
          value: 0
        },
        {
          id: 'ch_ct',
          title: 'Total de acertos',
          subtitle: '',
          value: 0
        },
        {
          id: 'ch_ct',
          title: 'Total de erros',
          subtitle: '',
          value: 0
        }
      ]
    },
    {
      group: [
        {
          id: 'scoreNature',
          title: 'Ciências da Natureza (CN)',
          subtitle: 'Nota com base no TRI',
          value: 0
        },
        {
          id: 'cn_ct',
          title: 'Total de acertos',
          subtitle: '',
          value: 0
        },
        {
          id: 'cn_ct',
          title: 'Total de erros',
          subtitle: '',
          value: 0
        }
      ]
    },
    {
      group: [
        {
          id: 'scoreMath',
          title: 'Matemática (MT)',
          subtitle: 'Nota com base no TRI',
          value: 0
        },
        {
          id: 'mt',
          title: 'Total de acertos',
          subtitle: '',
          value: 0
        },
        {
          id: 'mt',
          title: 'Total de erros',
          subtitle: '',
          value: 0
        }
      ]
    }
  ]
};

export const SUBJECT: Record<string, string> = {
  scoreLanguage: 'LINGUAGENS',
  lc_lt: 'LINGUAGENS',
  scoreHuman: 'HUMANAS',
  ch_ct: 'HUMANAS',
  scoreNature: 'NATUREZA',
  cn_ct: 'NATUREZA',
  scoreMath: 'MATEMÁTICA',
  mt: 'MATEMÁTICA'
};

export const NO_GRADE = 'Sem nota';
export const PARTIAL_AVERAGE_TEXT = 'Sua média parcial: ';
export const GENERAL_AVERAGE_TEXT = 'Sua média geral: ';

export const TOTAL_SUBJECT = 4;
