












import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Total extends Vue {
  @Prop() title!: string;
  @Prop() value!: number;
  @Prop({ default: false }) isHighlight!: boolean;
}
