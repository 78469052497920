














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Average extends Vue {
  @Prop() title!: string;
  @Prop() value!: number;
  @Prop() feedback!: string;
}
