





















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Observation extends Vue {
  @Prop({ required: true }) observation!: string;

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  hasContentHTML(content: string) {
    return content === '<p><br></p>' ? false : !!content;
  }
}
