
























import { Component, Vue, Prop } from 'vue-property-decorator';

import Box from '../Box/Box.vue';
import Message from '../Message/Message.vue';
import Character from '../../../Character/Character.vue';
import Card from '../../../Card/Card.vue';
import HeaderLoading from './HeaderLoading.vue';

import WarService from '@/services/War/WarService';

import { ConfigWar } from '@/globalInterfaces/War';

import { NAME_ROUTES_CONQUISTAE } from '@/router/routes/War';

import { HEADER } from '../../constants';

import { Card as ICard } from '../../../../interface/card';

import { STATUS } from '@/constant/Redaction';

@Component({
  components: {
    Box,
    Message,
    Character,
    Card,
    HeaderLoading
  }
})
export default class Header extends Vue {
  @Prop({ default: null }) redactionStatus!: string | null;

  private isLoadingCard = true;

  private card: ICard | null = null;

  private WarService = new WarService();

  created() {
    this.getCardUser();
  }

  get configWar(): ConfigWar | null {
    return this.$store.getters['War/configWar'];
  }

  get content() {
    if (!this.configWar?.isRedaction) {
      return HEADER[STATUS.CORRECTED];
    }

    return HEADER[this.redactionStatus || STATUS.CORRECTED];
  }

  async getCardUser() {
    if (!this.configWar?.isRedaction) {
      this.isLoadingCard = false;

      return;
    }

    try {
      this.isLoadingCard = true;

      const response = await this.WarService.getCardUser();

      this.card = response.card;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as informações da carta.',
        status: 'error'
      });
    } finally {
      this.isLoadingCard = false;
    }
  }

  handleStartGame() {
    this.$router.push({
      name: NAME_ROUTES_CONQUISTAE.WAR_ROOM
    });
  }
}
