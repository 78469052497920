


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Message extends Vue {
  @Prop({}) title!: string;
  @Prop({}) showIcon!: boolean;
  @Prop({}) content!: string;
  @Prop({}) showButton!: boolean;

  handleStartGame() {
    this.$emit('onStart');
  }
}
