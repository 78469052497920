










import { Vue, Component, Prop } from 'vue-property-decorator';

import LineChartAll from '@/components/Graphics/LineChartAll.vue';

import { Competences } from '../../interface';

@Component({
  components: {
    LineChartAll
  }
})
export default class Performance extends Vue {
  @Prop({ required: true }) redactionCompetences!: Competences;
}
