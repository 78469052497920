import { Vue, Component } from 'vue-property-decorator';
import { groupBy } from 'lodash';

import { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';

import {
  List,
  QuestionList,
  ListAnswersTRI,
  OptionQuestion,
  AnswerResult
} from '@/globalInterfaces/List';
import { NewStructureQuestionsResult } from '@/globalInterfaces/ListQuestions';

import { SIMULATED_CONSTANT } from '@/pages/Simulated/constants/SimulatedResult';

const QUESTION_ANSWERED = 'answered';
const QUESTION_SKIP = 'skip';
const AREA = {
  LANGUAGES: 'LINGUAGENS',
  HUMANITIES: 'HUMANAS',
  NATURE: 'NATUREZA'
};

@Component
export default class ListQuestionsResults extends Vue {
  protected list: List | null = null;
  protected questions: QuestionList[] | null = null;
  protected questionsResults: ListAnswersTRI | null = null;

  protected newQuestions: Record<string, any> = {};

  protected tabActive = SIMULATED_CONSTANT.TAB_ACTIVE_LANGUAGE;

  protected tabMenu: Array<TabsMenuInterface> = [];

  groupQuestionsByAreas(questions: QuestionList[]) {
    this.questions = questions;

    const newQuestion = groupBy(questions, 'area.name');

    return newQuestion;
  }

  separateQuestionsByArea(questions: Record<string, QuestionList[]>) {
    if (!questions) return;

    const newList = Object.keys(questions).map((key: string) => questions[key]);

    this.newQuestions = this.setStructureQuestionsList(newList);
  }

  setStructureQuestionsList(listQuestion: Array<QuestionList[]>) {
    const newList = listQuestion.reduce(
      (acc: NewStructureQuestionsResult, discipline: QuestionList[]) => {
        discipline
          .map((question: QuestionList) => ({
            ...question,
            id: question.ID,
            question: question.content,
            response: this.findAnswerQuestion(question) ? QUESTION_ANSWERED : QUESTION_SKIP,
            resolution: question.resolution,
            options: this.newListOption(question.options)
          }))
          .forEach((question: any) => {
            if (AREA.LANGUAGES.includes(question.area.title)) acc.lc_lt.push(question);
            else if (AREA.HUMANITIES.includes(question.area.title)) acc.ch_ct.push(question);
            else if (AREA.NATURE.includes(question.area.title)) acc.cn_ct.push(question);
            else acc.mt.push(question);
          });
        return acc;
      },
      {
        lc_lt: [],
        ch_ct: [],
        cn_ct: [],
        mt: []
      }
    );

    return newList;
  }

  findAnswerQuestion(question: QuestionList): AnswerResult | null {
    if (!this.questionsResults || !this.questionsResults.answers.length) return null;

    const foundAnswer = this.questionsResults.answers.find(
      (answer) => answer.listQuestionsQuestionsID === question.listQuestionsQuestionsID
    );

    return foundAnswer || null;
  }

  findAnswerOption(option: OptionQuestion): AnswerResult | null {
    if (!this.questionsResults || !this.questionsResults.answers.length) return null;

    const foundAnswerOption = this.questionsResults.answers.find(
      (answer) => answer.listQuestionsQuestionsID === option.listQuestionsQuestionsID
    );

    return foundAnswerOption || null;
  }

  findOption(option: AnswerResult, options: Array<OptionQuestion>): OptionQuestion | null {
    if (!options || !options.length || !option) return null;

    const foundOption = options.find(
      (optionItem) => optionItem.listQuestionQuestionsOptions === option.listQuestionsQuestionsOptionsID
    );

    return foundOption || null;
  }

  newListOption(options: Array<OptionQuestion>) {
    const newListOption = options.map((option: OptionQuestion) => {
      const answerOption = this.findAnswerOption(option);
      const findOption = answerOption ? this.findOption(answerOption, options) : null;

      return {
        ...option,
        title: option.content,
        idOptions: option.ID,
        correct: option.validated,
        answerOptionID: findOption?.ID,
        value: option.ID === findOption?.ID
      };
    });

    return newListOption;
  }
}
