export const STATUS_CLASS = {
  PENDENT: '--pending',
  CORRECTED: '--correct',
  DELETED: '--deleted',
  REJECTED: '--rejected',
  CHECKING: '--checked'
};

export const STATUS_FEEDBACK = {
  PENDENT: 'Aguardando correção',
  REJECTED: 'Redação Recusada'
};
