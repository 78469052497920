










































import { Component, Mixins, Watch } from 'vue-property-decorator';

import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import NotesTRI from '@/components/List/NotesTRI/NotesTRI.vue';
import QuestionsResultContainer from '@/components/List/QuestionsResultContainer/QuestionsResultContainer.vue';
import RedactionCorrection from '@/components/RedactionCorrection/RedactionCorrection.vue';
import Header from './components/Header/Header.vue';
import LevelingResultsLoading from './LevelingResultsLoading.vue';

import WarService from '@/services/War/WarService';
import ListService from '@/services/List/ListService';
import RedactionService from '@/services/Redaction/RedactionService';

import { ConfigWar } from '@/globalInterfaces/War';

import { SIMULATED_CONSTANT } from '@/pages/Simulated/constants/SimulatedResult';

import { Redaction } from '@/components/RedactionCorrection/interface';

import ListQuestionsResults from '@/mixins/ListQuestionsResults';

import {
  LIST_TAB,
  TAB_REDACTION,
  COMPONENT_QUESTION,
  COMPONENT_REDACTION,
  MSG_ERROR_LIST
} from './constants';

@Component({
  components: {
    NotesTRI,
    Tabs,
    QuestionsResultContainer,
    RedactionCorrection,
    Header,
    LevelingResultsLoading
  }
})
export default class LevelingResults extends Mixins(ListQuestionsResults) {
  private isLoadingList = true;
  private isLoadingListQuestions = true;
  private isLoadingQuestionsResult = true;
  private isLoadingRedaction = true;
  private tabActiveIsRedaction = false;

  private feedbackMessage = MSG_ERROR_LIST;
  private selectComponent = COMPONENT_QUESTION;

  private redaction: Redaction | null = null;

  private WarService = new WarService();
  private ListService = new ListService();
  private RedactionService = new RedactionService();

  mounted() {
    this.getList();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get configWar(): ConfigWar | null {
    return this.$store.getters['War/configWar'];
  }

  async getList() {
    try {
      this.isLoadingList = true;

      const list = await this.WarService.getListLeveling();

      if (!list) return;

      this.list = list;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados da lista.',
        status: 'error'
      });
    } finally {
      this.isLoadingList = false;
    }
  }

  @Watch('list', {
    deep: true
  })
  async getListQuestions() {
    if (!this.list) return;

    try {
      this.isLoadingListQuestions = true;

      const response = await this.ListService.getListQuestions({
        listQuestionsID: [this.list.ID],
        isOptions: true
      });

      if (response) {
        this.questions = response;

        this.separateQuestionsByArea(this.groupQuestionsByAreas(response));

        this.setTabMenu();
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a lista de questão',
        status: 'error'
      });
    } finally {
      this.isLoadingListQuestions = false;
    }
  }

  @Watch('list', {
    deep: true
  })
  async getListQuestionsResult() {
    if (!this.list) return;

    try {
      this.isLoadingQuestionsResult = true;

      const response = await this.ListService.getListQuestionsResultTRI(this.list.ID);

      if (response) {
        this.questionsResults = response;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as questões.',
        status: 'error'
      });

      console.error({ error });
    } finally {
      this.isLoadingQuestionsResult = false;
    }
  }

  @Watch('questionsResults', {
    deep: true
  })
  async getRedaction() {
    if (!this.questionsResults || !this.questionsResults.redactionID) return;

    try {
      this.isLoadingRedaction = true;

      const response = await this.RedactionService.getCorrectionRedaction(
        this.questionsResults.redactionID
      );

      if (response) {
        this.redaction = response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingRedaction = false;
    }
  }

  @Watch('tabActiveIsRedaction')
  changeActiveTabComponent() {
    if (this.tabActiveIsRedaction) {
      this.selectComponent = COMPONENT_REDACTION;
    } else this.selectComponent = COMPONENT_QUESTION;
  }

  setTabMenu() {
    let newFilteredTab: TabsMenuInterface[] = LIST_TAB.filter((tab: TabsMenuInterface) => (tab.id ? this.newQuestions[tab.id].length : ''));

    if (this.list?.theme?.ID && this.configWar?.isRedaction) {
      newFilteredTab = [
        ...newFilteredTab,
        {
          title: TAB_REDACTION.title,
          id: TAB_REDACTION.id,
          active: false
        }
      ];
    }

    newFilteredTab[0] = { ...newFilteredTab[0], active: true };

    this.tabMenu = newFilteredTab;

    this.setTabActive();
  }

  setTabActive() {
    this.tabActive = this.tabMenu[0].id || '';
  }

  nextTab() {
    const indexNextTab = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === this.tabActive)
      + SIMULATED_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexNextTab);

    if (indexNextTab !== -1) this.handleActiveTabSwitching(indexNextTab, this.tabMenu);
  }

  previousTab() {
    const indexPreviousTab = this.tabMenu.findIndex((tab: Record<string, any>) => tab.id === this.tabActive)
      - SIMULATED_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexPreviousTab);

    if (indexPreviousTab !== -1) this.handleActiveTabSwitching(indexPreviousTab, this.tabMenu);
  }

  setCurrentlyActiveTab(indexTab: number) {
    this.tabMenu = this.tabMenu.map((tab: TabsMenuInterface, index: number) => {
      if (index === indexTab) return { ...tab, active: true };
      return { ...tab, active: false };
    });
  }

  handleActiveTabSwitching(indexTabSelected: number, tabData: Array<TabsMenuInterface>) {
    this.tabMenu = tabData;

    this.tabActiveIsRedaction = tabData[indexTabSelected].id === TAB_REDACTION.id;

    this.tabActive = tabData[indexTabSelected].id || '';
  }
}
