import { STATUS } from '@/constant/Redaction';

export const HEADER = {
  [STATUS.REJECTED]: {
    title: 'Atenção, General!',
    background: 'linear-gradient(to bottom, #F5616D, #F03B46)',
    content:
      '<div><p>Sua redação foi recusada, mas isso não é o fim. É uma nova oportunidade para revisar suas estratégias, fortalecer seus argumentos e ajustar os detalhes para a próxima tentativa.</p> <p>Acredite em seu potencial, lute com dedicação e prepare-se para retornar com um texto ainda mais forte. O destino aguarda sua vitória, General!</p></div>',
    showIcon: false,
    showButton: true,
    character: '3',
    layout: '--second'
  },
  [STATUS.DELETED]: {
    title: 'Atenção, General!',
    background: 'linear-gradient(to bottom, #F5616D, #F03B46)',
    content:
      '<div><p>Sua redação foi recusada, mas isso não é o fim. É uma nova oportunidade para revisar suas estratégias, fortalecer seus argumentos e ajustar os detalhes para a próxima tentativa.</p> <p>Acredite em seu potencial, lute com dedicação e prepare-se para retornar com um texto ainda mais forte. O destino aguarda sua vitória, General!</p></div>',
    showIcon: false,
    showButton: true,
    character: '3',
    layout: '--second'
  },
  [STATUS.PENDENT]: {
    title: 'General, sua missão está em análise!',
    background: 'linear-gradient(to bottom, #3D4ED3, #3E58B2)',
    content:
      '<div><p>Nossas equipes de inteligência estão revisando sua redação e analisando seu desempenho com cautela. Em até 24 horas, você receberá seu relatório completo, indicando seu país de origem e a quantidade de tropas e recursos que serão designados para iniciar sua campanha de conquista.</p> <p>Prepare-se, pois o destino do seu império está em jogo. Use esse tempo para analisar as respostas das questões logo abaixo e se preparar para os desafios que virão!</p></div>',
    showIcon: true,
    showButton: false,
    character: '2',
    layout: '--first'
  },
  [STATUS.CHECKING]: {
    title: 'General, sua missão está em análise!',
    background: 'linear-gradient(to bottom, #3D4ED3, #3E58B2)',
    content:
      '<div><p>Nossas equipes de inteligência estão revisando sua redação e analisando seu desempenho com cautela. Em até 24 horas, você receberá seu relatório completo, indicando seu país de origem e a quantidade de tropas e recursos que serão designados para iniciar sua campanha de conquista.</p> <p>Prepare-se, pois o destino do seu império está em jogo. Use esse tempo para analisar as respostas das questões logo abaixo e se preparar para os desafios que virão!</p></div>',
    showIcon: true,
    showButton: false,
    character: '2',
    layout: '--first'
  },
  [STATUS.CORRECTED]: {
    title: 'General, trago novidades gloriosas!',
    background: 'linear-gradient(to bottom, #3D4ED3, #3E58B2)',
    content:
      '<div><p>Parabéns, General! Você completou com sucesso a Missão de Admissão e agora seu território está estabelecido. </p> <p>Suas tropas e recursos estão prontos, aguardando seu comando. Lidere com sabedoria, inspire coragem e planeje com estratégia, pois o futuro do seu império depende de suas decisões. A história será escrita pelo poder de suas ações. A glória o aguarda, General!</p></div>',
    showIcon: false,
    showButton: true,
    character: '4',
    layout: '--third'
  }
};

export const LIST_TAB = [
  { title: 'Linguagens', id: 'lc_lt', active: false },
  { title: 'Humanas', id: 'ch_ct', active: false },
  { title: 'Natureza', id: 'cn_ct', active: false },
  { title: 'Matemática', id: 'mt', active: false }
];

export const TAB_REDACTION = {
  slug: '',
  id: 'redacao',
  title: 'Redação',
  active: false
};

export const COMPONENT_QUESTION = 'QuestionsResultContainer';
export const COMPONENT_REDACTION = 'RedactionCorrection';

export const MSG_ERROR_LIST = 'Erro ao carregar lista. Tente novamente em instantes.';
