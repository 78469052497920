import { render, staticRenderFns } from "./NotesTRI.vue?vue&type=template&id=04f7bbd0&scoped=true&"
import script from "./NotesTRI.vue?vue&type=script&lang=ts&"
export * from "./NotesTRI.vue?vue&type=script&lang=ts&"
import style0 from "./NotesTRI.scss?vue&type=style&index=0&id=04f7bbd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f7bbd0",
  null
  
)

export default component.exports